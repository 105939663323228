<template>
  <div v-show="!isHidden" :class="CSSClasses" :style="computedStyle">
    <el-form-item
      :prop="name"
      :label="label"
      :class="elFormCssClasses"
      :style="elFormCss"
      :rules="[
        { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' },
        rules.controlValues
      ]"
    >
      <span slot="label" :class="labelCssClasses" :style="labelCss">
        {{ label }}
        <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
          <i class="el-icon-question"></i>
        </el-tooltip>
      </span>

      <el-date-picker
        :value="value"
        class="date_field_width"
        :type="isMonth ? 'month' : 'date'"
        :name="name"
        :size="size"
        :disabled="readonly"
        :placeholder="placeholder || $locale.main.placeholder.date_field"
        :format="isMonth ? 'MMM.yyyy' : 'dd.MM.yyyy'"
        value-format="yyyy-MM-dd"
        :picker-options="{ firstDayOfWeek: 1 }"
        @input="$emit('input', $event)"
      ></el-date-picker>
    </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import registryMixin from './registry_mixins'
import controlValues from '../control_values_mixin'
import InputLabel from '@/mixins/inputLabel.js'

export default {
  name: 'date_field',
  mixins: [mixin, registryMixin, controlValues, InputLabel],
  props: {
    controlValues: {
      type: Object,
      editor: 'ControlValues',
      description: 'Контроль значений',
      default: () => {
        return {
          operator: null,
          type: 'date',
          conditions: []
        }
      },
      options: { type: 'date' }
    },
    width: {
      type: String,
      description: 'Ширина'
    },
    label: {
      description: 'Название',
      type: String
    },
    placeholder: {
      description: 'Плейсхолдер',
      type: String
    },
    tooltip: {
      description: 'Подсказка',
      type: String
    },
    isMonth: {
      description: 'Только месяца',
      type: Boolean
    }
  },
  computed: {
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    }
  }
}
</script>

<style scoped>
  .date_field_width.el-date-editor.el-input{
    width: 100%;
  }
</style>
